
import { onMounted, defineComponent, ref } from "vue";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Vue3JsonEditor } from "vue3-json-editor";

import {
  createWebhook,
  deleteWebhook,
  getWebhooks,
  updateWebhook,
} from "@/store/api/webhooks";

import { getCustomersList } from "@/store/api/devices";
import { hideModal } from "@/core/helpers/dom";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { string } from "yup/lib/locale";

interface IPagination {
  page_number: number;
  page_size: number;
  customer_id: string;
  filters: {
    name: string;
    url: string;
  };
}
export default defineComponent({
  name: "Webhooks",
  components: {
    Vue3JsonEditor,
    KTDatatable,
  },
  setup() {
    const data = ref<any>([]);
    const list = ref<any>([]);
    const search = ref<string>("");
    const headerConfig = ref([
      {
        name: "Name",
        key: "name",
      },
      {
        name: "Customer Name",
        key: "customer_name",
      },
      {
        name: "HTTPS endpoint",
        key: "url",
      },
      {
        name: "API Key",
        key: "api_key",
      },
      {
        name: "Decoder",
        key: "is_decoded",
      },
      {
        name: "Enable Custom Payload",
        key: "is_custom_payload",
      },
      {
        name: "Action",
        key: "action",
      },
    ]);
    const loading = ref<boolean>(true);
    const customers = ref<any[]>([]);
    const webhooks_decoder = ref([
      {
        name: "True",
        value: "1",
      },
      {
        name: "False",
        value: "0",
      },
    ]);
    const edit = ref<boolean>(false);
    const view = ref<boolean>(false);
    const permissions = ref<any>("");
    const payload = ref<any>({
      id: 0,
      name: "",
      url: "",
      api_key: "",
      api_key_header: "default",
      se_account_id: 0,
      is_decoded: 0,
      is_custom_payload: 0,
      payload: {
        v: "v1.0",
        data: "{data}",
        time: "{time}",
        X_Axis: "{X_Axis}",
        Y_Axis: "{Y_Axis}",
        Z_Axis: "{Z_Axis}",
        device: "{device}",
        uplink: "{uplink}",
        battery: "{battery}",
        distance: "{distance}",
        fireAlarm: "{fireAlarm}",
        pickUpType: "{pickUpType}",
        pickUpEvent: "{pickUpEvent}",
        tamperEvent: "{tamperEvent}",
        temperature: "{temperature}",
        seqNumber: "{seqNumber}",
      },
    });

    const pagination = ref<IPagination>({
      page_number: 1,
      customer_id: "",
      page_size: 10,
      filters: {
        name: "",
        url: "",
      },
    });

    const total = ref<number>(0);

    const closeHexModal = ref<null | HTMLElement>(null);

    onMounted(async () => {
      permissions.value = localStorage.getItem("permissions");
      permissions.value = JSON.parse(permissions.value);
      setCurrentPageBreadcrumbs("Webhooks", [""]);
      getCustomersList().then((response) => {
        customers.value = response;
      });
      loadData();
    });
    const loadData = async () => {
      loading.value = true;
      let res = await getWebhooks(pagination.value);
      data.value = res.webhooks;
      list.value = res.webhooks;
      total.value = res.total_number_of_records;
      loading.value = false;
    };

    const currentChange = (val) => {
      pagination.value.page_number = val;
      loadData();
    };

    const itemsPerPageChange = (val) => {
      pagination.value.page_size = val;
      loadData();
    };

    const onCustomerChange = (event) => {
      payload.value.se_account_id = event.target.value;
      let pag = {
        customer_id: event.target.value,
      };
    };
    const dateTime = (value) => {
      return moment(value).format("DD-MM-YYYY h:mm:ss");
    };

    // pagination.value.customer_id
    const saveDownlink = () => {
      if (edit.value == true) {
        let id = payload.value.id;
        delete payload.value.id;
        updateWebhook(id, payload.value).then((res) => {
          resetModal();
          loadData();
        });
      } else {
        delete payload.value.id;
        createWebhook(payload.value).then((res) => {
          resetModal();
          loadData();
        });
      }
    };

    const resetModal = () => {
      payload.value.name = "";
      payload.value.url = 0;
      payload.value.api_key = "";
      payload.value.api_key_header = "default";
      payload.value.se_account_id = "0";
      payload.value.is_decoded = 0;
      payload.value.is_custom_payload = 0;
      payload.value.payload = {
        v: "v1.0",
        data: "{data}",
        time: "{time}",
        X_Axis: "{X_Axis}",
        Y_Axis: "{Y_Axis}",
        Z_Axis: "{Z_Axis}",
        device: "{device}",
        uplink: "{uplink}",
        battery: "{battery}",
        distance: "{distance}",
        fireAlarm: "{fireAlarm}",
        pickUpType: "{pickUpType}",
        pickUpEvent: "{pickUpEvent}",
        tamperEvent: "{tamperEvent}",
        temperature: "{temperature}",
      };
      document.getElementById("webhooks-close-modal")?.click();
    };
    const deleteHook = async (id) => {
      Swal.fire({
        title: "Are you sure you want to delete this Webhook?",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          deleteWebhook(id).then((res) => {
            loadData();
          });
        }
      });
    };

    const editWebhook = (data) => {
      payload.value.id = data.id;
      view.value = false;
      edit.value = true;
      payload.value.name = data.name;
      payload.value.url = data.url;
      payload.value.api_key = data.api_key;
      payload.value.api_key_header = data.api_key_header;
      payload.value.se_account_id = data.se_account_id;
      payload.value.is_decoded = data.is_decoded;
      payload.value.is_custom_payload = data.is_custom_payload;
      payload.value.payload = JSON.parse(data.payload);
    };

    const viewWebhook = (data) => {
      payload.value.id = data.id;
      view.value = true;
      payload.value.name = data.name;
      payload.value.url = data.url;
      payload.value.api_key = data.api_key;
      payload.value.api_key_header = data.api_key_header;
      payload.value.se_account_id = data.se_account_id;
      payload.value.is_decoded = data.is_decoded;
      payload.value.is_custom_payload = data.is_custom_payload;
      payload.value.payload = JSON.parse(data.payload);
    };
    const searchItems = () => {
      pagination.value.page_number = 1;
      if (search.value !== "") {
        pagination.value.filters.name = search.value;
      } else {
        pagination.value.filters.name = "";
      }
      loadData();
    };
    const onJsonChange = (newJson) => {
      payload.value.payload = newJson;
    };

    return {
      total,
      pagination,
      data,
      headerConfig,
      loading,
      currentChange,
      itemsPerPageChange,
      saveDownlink,
      customers,
      webhooks_decoder,
      onCustomerChange,
      deleteHook,
      closeHexModal,
      editWebhook,
      viewWebhook,
      edit,
      view,
      payload,
      searchItems,
      search,
      dateTime,
      permissions,
      onJsonChange,
    };
  },
});
